import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKSocialButton from "components/MKSocialButton";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ReactPlayer from 'react-player';

function Videos({items}) {

  // const videoList = [
  //   { src: "https://www.youtube.com/watch?v=_OKAwz2MsJs", title: "Joker" },
  //   { src: "https://www.youtube.com/watch?v=STScKOUpXR8", title: "Venom" },
  //   { src: "https://www.youtube.com/watch?v=_OKAwz2MsJs", title: "Joker" },
  //   { src: "https://www.youtube.com/watch?v=STScKOUpXR8", title: "Venom" },
  //   { src: "https://www.youtube.com/watch?v=_OKAwz2MsJs", title: "Joker" },
  //   { src: "https://www.youtube.com/watch?v=STScKOUpXR8", title: "Venom" },
  //   { src: "https://www.youtube.com/watch?v=_OKAwz2MsJs", title: "Joker" },
  //   { src: "https://www.youtube.com/watch?v=STScKOUpXR8", title: "Venom" },
  //   { src: "https://www.youtube.com/watch?v=_OKAwz2MsJs", title: "Joker" },
  //   { src: "https://www.youtube.com/watch?v=STScKOUpXR8", title: "Venom" },
  //   { src: "https://www.youtube.com/watch?v=_OKAwz2MsJs", title: "Joker" },
  //   { src: "https://www.youtube.com/watch?v=STScKOUpXR8", title: "Venom" },
  // ];

  return (
    <MKBox component="section" py={7} style={{ backgroundColor: "white", boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.5)', minHeight: '85vh' }} >
      {items && (
        <Container>
          {/* <Grid container justifyContent="center" xs={12} lg={12} textAlign="center" mb={3}>
            <MKSocialButton color="youtube" onClick={ () => { 
              window.open("https://www.youtube.com/channel/UCObhA8nKdLn1bdJWDfdm_WQ/featured?sub_confirmation=1")
            }}  
            >
              <YouTubeIcon />
              &nbsp;SUBSCRIBE
            </MKSocialButton>
          </Grid> */}
          <Grid container spacing={3}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
                <ReactPlayer
                  url={item.video_link}
                  width="100%"
                  height="auto"
                  controls
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </MKBox>
  );
}

export default Videos;
